import React, { useEffect } from "react";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SearchDialog(props) {
  const { onClose, open } = props;

  const gateways = useSelector((state) => state.gateways);

  useEffect(() => {}, [props.data]);

  const navigate = useNavigate();

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogContent>
        <Autocomplete
          id="search"
          fullWidth
          options={gateways}
          getOptionLabel={(option) => (option.name ? option.name : option.id)}
          renderInput={(params) => <TextField {...params} label="Search" />}
          onChange={(event, value) => {
            navigate("/gateway/" + value.id);
            onClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

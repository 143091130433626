import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import CONFIG from "../../config";
import { useTranslation } from "react-i18next";

export default function PageTitle({
  title,
  titleNoTranslate = false,
  children,
}) {
  const themeMode = localStorage.getItem("theme") || CONFIG.defaultTheme;

  const { t } = useTranslation("common");

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            flexGrow: 1,
          }}
          color={themeMode === "dark" ? "primary" : "#000"}
        >
          {titleNoTranslate ? title : t("common." + title)}
        </Typography>
        <Stack direction="row" spacing={1}>
          {children}
        </Stack>
      </Box>
    </Grid>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import gatewaysReducer from "./reducers/gateways";
import gatewayReducer from "./reducers/gateway";
import snackbarReducer from "./reducers/snackbar";
import isMobileReducer from "./reducers/isMobile";
import themeModeReducer from "./reducers/themeMode";
import makesReducer from "./reducers/makes";

export const store = configureStore({
  reducer: {
    gateways: gatewaysReducer,
    gateway: gatewayReducer,
    makes: makesReducer,
    snackbar: snackbarReducer,
    isMobile: isMobileReducer,
    themeMode: themeModeReducer,
  },
});

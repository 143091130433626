import React from "react";
import { IconButton, Tooltip } from "@mui/material";

export default function IconButtonBase({ children, ...props }) {
  return (
    <Tooltip title={props.title} placement="bottom">
      <IconButton
        id={props.label}
        size="large"
        edge="start"
        color="inherit"
        aria-label={props.label}
        sx={{ mr: 2, color: "white" }}
        onClick={() => props.action()}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}

import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function RowsPerPageDropdown() {
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage") || "en"
  );

  const { t } = useTranslation("common");

  return (
    <TextField
      id="outlined-select-rowsPerPage"
      select
      fullWidth
      label={t("common.rows_per_page")}
      value={rowsPerPage}
      onChange={(event) => {
        localStorage.setItem("rowsPerPage", event.target.value);
        setRowsPerPage(event.target.value);
      }}
    >
      <MenuItem value={5}>5</MenuItem>
      <MenuItem value={10}>10</MenuItem>
      <MenuItem value={25}>25</MenuItem>
      <MenuItem value={50}>50</MenuItem>
      <MenuItem value={100}>100</MenuItem>
      <MenuItem value={250}>250</MenuItem>
      <MenuItem value={500}>500</MenuItem>
      <MenuItem value={1000}>1000</MenuItem>
    </TextField>
  );
}

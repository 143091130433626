import React, { memo, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import GatewayStats from "../components/Cards/GatewayStats";
import PageTitle from "../components/Layout/PageTitle";
import RebootGatewayButton from "../components/Buttons/RebootGateway";
import EditGatewayButton from "../components/Buttons/EditGateway";
import GenerateConfigButton from "../components/Buttons/GenerateConfig";
import ContainersTable from "../components/Tables/Containers";
import setGateway from "../functions/setGateway";

const Gateway = memo(() => {
  const params = useParams();
  const { gateways, gateway } = useSelector((state) => state);

  useEffect(() => {
    if (gateways && gateways.length) {
      let gateway = gateways.find((gateway) => gateway.id === params.id);
      setGateway(gateway);
    }
  }, [params.id, gateways]);

  return (
    <Grid container spacing={1}>
      <PageTitle title={gateway.name} titleNoTranslate>
        <RebootGatewayButton />
        <EditGatewayButton />
        <GenerateConfigButton />
      </PageTitle>
      <Grid item xs={12}>
        <GatewayStats />
      </Grid>
      <Grid item xs={12}>
        <ContainersTable />
      </Grid>
    </Grid>
  );
});

export default withAuthenticationRequired(Gateway);

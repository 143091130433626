const initialState = { open: false, message: "" };

//snackbar open reducer
export default function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case "snackbar/update":
      return {
        ...state,
        open: true,
        message: action.payload.message,
      };
    case "snackbar/close":
      return {
        ...state,
        open: false,
        message: "",
      };

    default:
      return state;
  }
}

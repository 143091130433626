//navbar appbar

import React, { useState } from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import AccountMenu from "./AccountMenu";

import { MenuRounded } from "@mui/icons-material";
import CONFIG from "../../config";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import { MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function NavBar(props) {
  const { handleCollapsed } = props;

  const isMobile = useSelector((state) => state.isMobile);
  const themeMode = useSelector((state) => state.themeMode);

  const [anchorEl, setAnchorEl] = useState(null);

  const { t } = useTranslation("common");

  const handleCollapsedF = () => {
    if (localStorage.getItem("sidebarCollapsed") === "true") {
      localStorage.setItem("sidebarCollapsed", "false");
    } else {
      localStorage.setItem("sidebarCollapsed", "true");
    }
    handleRotate();
    handleCollapsed();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [rotateChevron, setRotateChevron] = useState(
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false
  );

  const handleRotate = () => setRotateChevron(!rotateChevron);

  const rotate = rotateChevron ? "rotate(0)" : "rotate(-180deg)";
  return (
    <AppBar position="static">
      <Toolbar>
        <MenuRounded
          onClick={(event) => {
            //if isMobile, open menu, else collapse sidebar
            isMobile ? setAnchorEl(event.currentTarget) : handleCollapsedF();
          }}
          style={{ transform: rotate, transition: "all 0.2s linear" }}
          sx={{
            mr: 2,
            color: themeMode === "dark" ? "#8ba1b7" : "#fff",
          }}
        />
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem>
            <Link
              to="/gateway"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">{t("common.gateways")}</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/user"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">{t("common.users")}</Typography>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/settings"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={handleClose}
            >
              <Typography variant="body1">{t("common.settings")}</Typography>
            </Link>
          </MenuItem>
        </Menu>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            {CONFIG.siteTitle}
          </Link>
        </Typography>

        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
}

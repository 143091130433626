import React, { useEffect, useMemo, useRef } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import CONFIG from "./config";
import Layout from "./components/Layout/Layout";
import i18n from "./i18n";
import fetchGateways from "./functions/fetchGateways";
import fetchUsers from "./functions/fetchUsers";
import { useSelector } from "react-redux";
import setThemeMode from "./functions/setThemeMode";
import setIsMobile from "./functions/setIsMobile";
import RoutesList from "./routes";
import fetchMakes from "./functions/fetchMakes";

function App() {
  const interval = useRef(null);
  const { user, getAccessTokenSilently } = useAuth0();

  const themeMode = useSelector((state) => state.themeMode);

  const fetchToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: CONFIG.auth0.audience,
          scope: "read:current_user",
        },
      });

      localStorage.setItem("access_token", accessToken);
    } catch (e) {
      console.log(e.message);
    }
  };

  fetchToken();

  useEffect(() => {
    let lng = localStorage.getItem("language") || "en";
    i18n.changeLanguage(lng);

    //get browser preffered dark mode
    const darkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    //check if user has set dark mode
    const themeFromLocal = localStorage.getItem("theme");

    //if darkmode & themeFromLocal is not set, set dark mode
    if (darkMode && !themeFromLocal) {
      setThemeMode("dark");
    }

    if (window.innerWidth < 900) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    //event listener to detect if mobile
    window.addEventListener("resize", () => {
      if (window.innerWidth < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    if (!user) return;

    fetchGateways();
    fetchUsers();
    fetchMakes();

    interval.current = setInterval(
      () => {
        fetchGateways();
        fetchUsers();
        fetchMakes();
      },
      //every 4 minutes
      240000
    );

    return () => {
      clearInterval(interval.current);
    };
  }, [user]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode === "dark" ? "dark" : "light",
          gradient:
            themeMode === "dark"
              ? "linear-gradient( to bottom, #191B1F 0%, #191B1F 550px, #25282D 550px, #25282D 150%)"
              : "linear-gradient( to bottom, " +
                CONFIG.primaryColour +
                " 0%, " +
                CONFIG.primaryColour +
                " 550px, #EFEFEF 550px , #EFEFEF 150%)",
          primary: {
            main: CONFIG.primaryColour,
          },
          secondary: {
            main: CONFIG.secondaryColour,
          },
        },
        typography: {
          fontFamily: "Plus Jakarta Sans",
        },
      }),
    [themeMode]
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          <RoutesList />
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import { Grid } from "@mui/material";
import React, { memo } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import PageTitle from "../components/Layout/PageTitle";
import MakesTable from "../components/Tables/Makes";
import ThemeModeDropdown from "../components/Dropdowns/ThemeMode";
import LanguageDropdown from "../components/Dropdowns/Language";
import RowsPerPageDropdown from "../components/Dropdowns/RowsPerPage";

const Settings = memo(() => {
  return (
    <Grid container spacing={1}>
      <PageTitle title="settings" />
      <Grid item xs={12} md={4}>
        <ThemeModeDropdown />
      </Grid>
      <Grid item xs={12} md={4}>
        <LanguageDropdown />
      </Grid>
      <Grid item xs={12} md={4}>
        <RowsPerPageDropdown />
      </Grid>
      <Grid item xs={12}>
        <MakesTable />
      </Grid>
    </Grid>
  );
});

export default withAuthenticationRequired(Settings);

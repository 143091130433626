import { store } from "../store";

export default function updateSnackbarMessage(message) {
  store.dispatch({
    type: "snackbar/update",
    payload: {
      open: true,
      message: message,
    },
  });
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CONFIG from "../../config";
import { getHeaders } from "../../api/getHeaders";
import { putData } from "../../api/Put";

import Editor from "@monaco-editor/react";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

const YAMLEditor = (props) => {
  const [yamlContent, setYAMLContent] = useState("");

  const themeMode = localStorage.getItem("theme") || CONFIG.defaultTheme;

  const { t } = useTranslation("common");

  const handleSave = async () => {
    let yamlObject = {};

    if (props.YAMLEditorFile === "gatewayrs_settings") {
      yamlObject = {
        gatewayrs_settings: yamlContent,
      };
    } else {
      yamlObject = {
        docker_file: yamlContent,
      };
    }

    await putData(
      "/gateway_types/" + props.make + "/" + props.YAMLEditorFile,
      yamlObject
    );

    updateSnackbarMessage(t("common.file_saved_successfully"));

    props.handleClose();
  };

  useEffect(() => {
    const fetchFileContentsFromURL = async () => {
      let url =
        CONFIG.API_URL +
        "/gateway_types/" +
        props.make +
        "/" +
        props.YAMLEditorFile;

      const headers = await getHeaders();
      const response = await fetch(url, {
        headers: headers,
      });

      //contents is yaml
      const yamlObject = await response.json();

      //key of the yamlObject depends if its docker_file or gatewayrs_settings

      if (props.YAMLEditorFile === "gatewayrs_settings") {
        setYAMLContent(yamlObject.gatewayrs_settings);
      }
      if (props.YAMLEditorFile === "dockerfile") {
        setYAMLContent(yamlObject.docker_file);
      }
    };

    if (props.YAMLEditorFile && props.make) {
      fetchFileContentsFromURL();
    }
  }, [props.YAMLEditorFile, props.make]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
        setYAMLContent("");
      }}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>{t("common.edit_file")}</DialogTitle>
      <DialogContent>
        {!!yamlContent && (
          <Editor
            height="60vh"
            defaultLanguage="json"
            theme={themeMode === "dark" ? "vs-dark" : "light"}
            defaultValue={yamlContent}
            onChange={(value) => setYAMLContent(value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            props.handleClose();
            setYAMLContent("");
          }}
          color="secondary"
        >
          {t("common.cancel")}
        </Button>
        <Button variant="outlined" color="primary" onClick={() => handleSave()}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YAMLEditor;

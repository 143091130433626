// dropdown for navbar for account, show icon and dropdown menu when clicked

import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import {
  AccountCircle,
  Brightness4,
  Brightness7,
  Search,
  Translate,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import SearchDialog from "../Dialogs/Search";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IconButtonBase from "../Buttons/IconButtonBase";
import handleThemeChange from "../../functions/handleThemeChange";
import updateLanguage from "../../functions/updateLanguage";

export default function AccountMenu() {
  const { logout } = useAuth0();
  const { t } = useTranslation("common");

  const themeMode = useSelector((state) => state.themeMode);
  const [searchOpen, setSearchOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);

  const handleClick = () => {
    setAnchorEl((prev) =>
      prev ? null : document.getElementById(t("common.account"))
    );
  };

  const handleClickLanguage = () => {
    setAnchorElLanguage((prev) =>
      prev ? null : document.getElementById(t("common.language"))
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseLanguage = () => {
    setAnchorElLanguage(null);
  };

  return (
    <>
      <IconButtonBase
        title={t("common.search")}
        label={t("common.search")}
        action={() => {
          setSearchOpen(true);
        }}
      >
        <Search />
      </IconButtonBase>

      <IconButtonBase
        title={
          themeMode === "dark"
            ? t("common.switch_to_light_mode")
            : t("common.switch_to_dark_mode")
        }
        label={
          themeMode === "dark"
            ? t("common.switch_to_light_mode")
            : t("common.switch_to_dark_mode")
        }
        action={() => handleThemeChange()}
      >
        {themeMode === "dark" ? <Brightness7 /> : <Brightness4 />}
      </IconButtonBase>

      <IconButtonBase
        title={t("common.language")}
        label={t("common.language")}
        action={() => handleClickLanguage()}
      >
        <Translate />
      </IconButtonBase>

      <IconButtonBase
        title={t("common.account")}
        label={t("common.account")}
        action={() => handleClick()}
      >
        <AccountCircle />
      </IconButtonBase>

      {
        //dropdown for account
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          {t("common.logout")}
        </MenuItem>
      </Menu>

      {
        //dropdown for language
      }
      <Menu
        anchorEl={anchorElLanguage}
        keepMounted
        open={!!anchorElLanguage}
        onClose={handleCloseLanguage}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {
          //dropdown to change language
        }
        <MenuItem
          onClick={() => {
            updateLanguage("en");
            setAnchorElLanguage(null);
          }}
        >
          English
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateLanguage("nl");
            setAnchorElLanguage(null);
          }}
        >
          Nederlands
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateLanguage("de");
            setAnchorElLanguage(null);
          }}
        >
          Deutsch
        </MenuItem>
      </Menu>
      <SearchDialog
        open={searchOpen}
        onClose={() => {
          setSearchOpen(false);
        }}
      />
    </>
  );
}

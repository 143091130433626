import React, { useState } from "react";
import NavBar from "./NavBar";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import SnackbarBase from "./SnackbarBase";
import SidebarLayout from "./Sidebar";

export default function Layout(props) {
  const themeMode = useSelector((state) => state.themeMode);
  const isMobile = useSelector((state) => state.isMobile);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false
  );

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <NavBar handleCollapsed={handleCollapsed} />
      <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        {
          //if mobile, set sidebar to null
          isMobile ? null : <SidebarLayout collapsed={collapsed} />
        }

        <Container
          maxWidth="false"
          sx={{
            p: 1,
            //if theme = dark, set bg to black
            //else set bg to white
            backgroundColor: themeMode === "dark" ? "#000" : "#fff",
          }}
          disableGutters
        >
          {props.children}
        </Container>
      </div>

      <SnackbarBase />
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RebootGateways = ({ open, data, onClose, onConfirm, type }) => {
  const { t } = useTranslation("common");

  const [items, setItems] = useState([]);

  const gateways = useSelector((state) => state.gateways);
  useEffect(() => {
    if (type === "gateway") {
      if (gateways && gateways.length > 0) {
        setItems(gateways.filter((gateway) => data.includes(gateway.id)));
      }
    }
  }, [gateways, type, data]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("common.reboot_gateways_title")}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t("common.reboot_gateways_message")}
        </Typography>
        <List>
          {items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          {t("common.cancel")}
        </Button>
        <Button onClick={onConfirm} variant="outlined" color="error">
          {t("common.reboot")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RebootGateways;

// Desc: Put API call to update data
import axios from "axios";
import CONFIG from "../config";
import { getHeaders } from "./getHeaders";

//axios api call to fetch data from the server
export const putData = async (url, data) => {
  let headers = await getHeaders();

  try {
    const response = await axios.put(CONFIG.API_URL + url, data, {
      headers: headers,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

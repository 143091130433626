import React, { memo } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import GatewaysStats from "../components/Cards/GatewaysStats";
import GatewaysTable from "../components/Tables/GatewaysTable";
import AddGatewayButton from "../components/Buttons/AddGateway";
import PageTitle from "../components/Layout/PageTitle";
import { Grid } from "@mui/material";

const Gateways = memo(() => {
  return (
    <Grid container spacing={1}>
      <PageTitle title="gateways">
        <AddGatewayButton />
      </PageTitle>
      <Grid item xs={12}>
        <GatewaysStats />
      </Grid>
      <Grid item xs={12}>
        <GatewaysTable />
      </Grid>
    </Grid>
  );
});

export default withAuthenticationRequired(Gateways);

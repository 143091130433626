const initialState = {};

function gatewaysReducer(state = initialState, action) {
  switch (action.type) {
    case "gateway/update":
      return action.payload;
    default:
      return state;
  }
}

export default gatewaysReducer;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import editGateway from "../../functions/editGateway";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import fetchGateways from "../../functions/fetchGateways";

function GatewayEdit(props) {
  const [name, setName] = useState("");
  const [make, setMake] = useState("");
  const id = props.id;

  const gateways = useSelector((state) => state.gateways);

  const { t } = useTranslation("common");

  const handleAddGateway = () => {
    const editGatewayItem = {
      name: name,
      make: make,
    };

    editGateway(editGatewayItem, id);

    updateSnackbarMessage(t("common.gateway_updated"));

    //wait 2 seconds for gateway to update
    setTimeout(() => {
      fetchGateways();
    }, 1000);

    props.handleClose();
  };

  useEffect(() => {
    const gateway = gateways.find((gateway) => gateway.id === id);

    if (!gateway) {
      return;
    }
    setName(gateway.name);
    setMake(gateway.gateway_type);
  }, [gateways, id]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t("common.edit_gateway")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("common.name")}
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <TextField
          margin="dense"
          label={t("common.make")}
          type="text"
          fullWidth
          select
          value={make}
          onChange={(event) => setMake(event.target.value)}
        >
          <MenuItem value="SENSECAP-M1">SenseCAP M1</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.handleClose}
          color="secondary"
        >
          {t("common.cancel")}
        </Button>
        <Button variant="outlined" onClick={handleAddGateway} color="primary">
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GatewayEdit;

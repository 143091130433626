import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "./Card";
import { Router, AccessTime, Public } from "@mui/icons-material";
import { formatDate } from "../../functions/formatDate";
import fetchGatewayStatus from "../../functions/fetchGatewayStatus";
import { useSelector } from "react-redux";

export default function GatewayStats(props) {
  const { t } = useTranslation("common");

  const gateway = useSelector((state) => state.gateway);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.gateways") + " " + t("common.status")}
          action={fetchGatewayStatus(gateway)}
          avatar={<Router sx={{ fontSize: "4em" }} />}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.last_seen")}
          action={
            gateway && gateway.last_updated
              ? formatDate(gateway.last_updated)
              : "N/A"
          }
          avatar={<AccessTime sx={{ fontSize: "4em" }} />}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.make")}
          action={
            gateway && gateway.gateway_type ? gateway.gateway_type : "N/A"
          }
          avatar={<Public sx={{ fontSize: "4em" }} />}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.local") + " " + t("common.ip_address")}
          action={gateway && gateway.lan_ip ? gateway.lan_ip : "N/A"}
          avatar={<Public sx={{ fontSize: "4em" }} />}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.wan") + " " + t("common.ip_address")}
          action={gateway && gateway.wan_ip ? gateway.wan_ip : "N/A"}
          avatar={<Public sx={{ fontSize: "4em" }} />}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.vpn") + " " + t("common.ip_address")}
          action={gateway && gateway.vpn_ip ? gateway.vpn_ip : "N/A"}
          avatar={<Public sx={{ fontSize: "4em" }} />}
        />
      </Grid>
    </Grid>
  );
}

import React from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Add, CheckBox, Delete, RestartAlt } from "@mui/icons-material";

export default function TableActions(props) {
  return (
    <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
      {props.actions.map((action) => {
        return (
          <Tooltip title={action.name} key={action.name}>
            <IconButton
              onClick={() => {
                action.action();
              }}
            >
              {action.icon === "Delete" && <Delete />}
              {action.icon === "Status" && <CheckBox />}
              {action.icon === "Add" && <Add />}
              {action.icon === "Reboot" && <RestartAlt />}
            </IconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
}

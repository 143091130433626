import React from "react";
import { MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import handleThemeChange from "../../functions/handleThemeChange";

export default function ThemeModeDropdown() {
  const themeMode = useSelector((state) => state.themeMode);
  const { t } = useTranslation("common");
  return (
    <TextField
      id="outlined-select-theme-mode"
      select
      fullWidth
      label={
        themeMode === "dark"
          ? t("common.switch_to_light_mode")
          : t("common.switch_to_dark_mode")
      }
      value={themeMode}
      onChange={(event) => handleThemeChange(event.target.value)}
    >
      <MenuItem value="light">{t("common.light")}</MenuItem>
      <MenuItem value="dark">{t("common.dark")}</MenuItem>
    </TextField>
  );
}

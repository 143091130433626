import React from "react";
import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GatewayAdd from "../Dialogs/GatewayAdd";

export default function AddGatewayButton(props) {
  const [addGatewayDialogOpen, setAddGatewayDialogOpen] = React.useState(false);

  const { t } = useTranslation("common");

  const handleAddGatewayDialogOpen = () => {
    setAddGatewayDialogOpen(true);
  };

  return (
    <>
      <Tooltip title={t("common.add_gateway")}>
        <Button
          variant="outlined"
          color="success"
          onClick={handleAddGatewayDialogOpen}
          startIcon={<Add />}
        >
          {t("common.add_gateway")}
        </Button>
      </Tooltip>
      <GatewayAdd
        open={addGatewayDialogOpen}
        handleClose={() => setAddGatewayDialogOpen(false)}
      />
    </>
  );
}

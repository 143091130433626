import React from "react";
import { Typography } from "@mui/material";

import { formatDate } from "../../functions/formatDate";

export default function datatableColumnDefs(t, table_name, navigate) {
  const containers = [
    {
      name: "ID",
      label: t("common.id"),
      options: {
        display: false,
      },
    },
    {
      name: "Names",
      label: t("common.name"),
      options: {
        customBodyRender: (value) => {
          //drop Gateway from name
          value = value.replace("gateway", "");

          //remove tmp- from name
          value = value.replace("tmp-", "");

          //remove -1 from name
          value = value.replace("-1", "");

          //replace - with space
          value = value.replace("-", " ");

          //uppercase first letter of each word
          value = value.replace(/\b\w/g, (l) => l.toUpperCase());

          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "Image",
      label: t("common.image"),
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "Ports",
      label: t("common.ports"),
      options: {
        customBodyRender: (value) => {
          //if value is empty, no ports are exposed
          if (value === "") {
            return <Typography variant="body2">{t("common.none")}</Typography>;
          }
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "State",
      label: t("common.state"),
      options: {
        customBodyRender: (value) => {
          //uppercase first letter
          value = value.charAt(0).toUpperCase() + value.slice(1);
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "RunningFor",
      label: t("common.running_for"),
      options: {
        customBodyRender: (value) => {
          //remove the string " ago"
          value = value.replace(" ago", "");

          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "CreatedAt",
      label: t("common.created_at"),
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2">{formatDate(value)}</Typography>;
        },
      },
    },
  ];

  const gateways = [
    {
      name: "id",
      label: t("common.id"),
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "name",
      label: t("common.name"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body2"
              onClick={() => {
                //redirect to gateway page
                navigate("/gateway/" + tableMeta.rowData[0]);
              }}
              sx={{
                // on hover set to theme.palette.primary.main
                //and Underlined
                "&:hover": {
                  color: "primary.main",
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "gateway_eui",
      label: t("common.gateway_eui"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "wan_ip",
      label: t("common.wan") + " " + t("common.ip_address"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "lan_ip",
      label: t("common.local") + " " + t("common.ip_address"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "vpn_ip",
      label: t("common.vpn") + " " + t("common.ip_address"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "gateway_type",
      label: t("common.make"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "last_updated",
      label: t("common.status"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // if date is older than 6 minutes, set to offline status
          let status = "offline";

          let last_updated = new Date(value);
          let now = new Date();

          //check if newer than 6 minutes
          if (now - last_updated < 360000) {
            status = "online";
          }

          return (
            <Typography
              variant="body2"
              color={status === "online" ? "green" : "red"}
            >
              {status}
            </Typography>
          );
        },
      },
    },
  ];

  const users = [
    {
      name: "id",
      label: t("common.id"),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "email",
      label: t("common.email"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography
              variant="body2"
              onClick={() => {
                //
              }}
              sx={{
                // on hover set to theme.palette.primary.main
                //and Underlined
                "&:hover": {
                  color: "primary.main",
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "status",
      label: t("common.status"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
  ];

  //return the correct columns for the table
  switch (table_name) {
    case "containers":
      return containers;
    case "gateways":
      return gateways;
    case "users":
      return users;
    default:
      return [];
  }
}

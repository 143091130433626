import React from "react";
import { Button, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import GatewayEdit from "../Dialogs/GatewayEdit";
import { useSelector } from "react-redux";

export default function EditGatewayButton() {
  const [editGatewayDialogOpen, setEditGatewayDialogOpen] =
    React.useState(false);

  const gateway = useSelector((state) => state.gateway);

  const { t } = useTranslation("common");

  const handleEditGatewayDialogOpen = () => {
    setEditGatewayDialogOpen(true);
  };

  return (
    <>
      <Tooltip title={t("common.edit_gateway")}>
        <Button
          variant="outlined"
          color="success"
          onClick={handleEditGatewayDialogOpen}
          startIcon={<Edit />}
        >
          {t("common.edit")}
        </Button>
      </Tooltip>
      <GatewayEdit
        open={editGatewayDialogOpen}
        handleClose={() => setEditGatewayDialogOpen(false)}
        id={gateway.id}
      />
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import "./i18n";
import { ProSidebarProvider } from "react-pro-sidebar";
import { store } from "./store";
import { Provider } from "react-redux";
import CONFIG from "./config";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    domain={CONFIG.auth0.domain}
    clientId={CONFIG.auth0.clientId}
    redirectUri={window.location.origin}
    audience={CONFIG.auth0.audience}
    cacheLocation="localstorage"
  >
    <ProSidebarProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider store={store}>
          <App />
        </Provider>
      </LocalizationProvider>
    </ProSidebarProvider>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

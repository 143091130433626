import React from "react";
import { Button, Tooltip } from "@mui/material";
import { RestartAlt } from "@mui/icons-material";
import RebootGateways from "../Dialogs/RebootGateways";
import rebootGateways from "../../functions/rebootGateways";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import { useTranslation } from "react-i18next";
import fetchGateways from "../../functions/fetchGateways";
import { useSelector } from "react-redux";

export default function RebootGatewayButton() {
  const [rebootGatewayDialogOpen, setRebootGatewayDialogOpen] =
    React.useState(false);

  const { t } = useTranslation("common");

  const gateway = useSelector((state) => state.gateway);

  const handleRebootGatewayDialogOpen = () => {
    setRebootGatewayDialogOpen(true);
  };

  return (
    <>
      <Tooltip title="Add Device">
        <Button
          variant="outlined"
          color="error"
          onClick={handleRebootGatewayDialogOpen}
          startIcon={<RestartAlt />}
        >
          {t("common.reboot")}
        </Button>
      </Tooltip>
      <RebootGateways
        open={rebootGatewayDialogOpen}
        data={[gateway.id]}
        onClose={() => setRebootGatewayDialogOpen(false)}
        type="gateway"
        onConfirm={async () => {
          //reboot gateways
          rebootGateways([gateway.id]);
          updateSnackbarMessage(t("common.gateways_rebooted"));
          setRebootGatewayDialogOpen(false);
          fetchGateways();
        }}
      />
    </>
  );
}

import React from "react";
import DataTableBase from "./DataTable";
import datatableColumnDefs from "./dataTableColumnDefs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function ContainersTable() {
  const gateway = useSelector((state) => state.gateway);

  const { t } = useTranslation("common");

  return (
    <DataTableBase
      title={t("common.containers")}
      columns={datatableColumnDefs(t, "containers")}
      data={
        gateway && gateway.containers && gateway.containers.length > 0
          ? gateway.containers
          : []
      }
    />
  );
}

const CONFIG = {
  auth0: {
    domain: "connectiot.eu.auth0.com",
    clientId: "7ZpRTIJBddbhAtX6pY4JcTMzkrnBo0FZ",
    audience: "https://api.connectiot.nl",
  },
  siteTitle: "Gateway Manager",
  primaryColour: "#006633",
  secondaryColour: "#7785AC",
  defaultTheme: "dark",
  defaultLocale: "en",
  API_URL: "https://api.gateways.connect-iot.eu",
};

module.exports = CONFIG;

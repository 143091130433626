import { Routes, Route } from "react-router-dom";
import NotFound from "./containers/NotFound";
import Gateways from "./containers/Gateways";
import Users from "./containers/Users";
import Gateway from "./containers/Gateway";
import Settings from "./containers/Settings";

export default function RoutesList() {
  return (
    <Routes>
      <Route path="/" exact element={<Gateways />} />
      <Route path="/gateway" exact element={<Gateways />} />
      <Route path="/gateway/:id" exact element={<Gateway />} />
      <Route path="/user" exact element={<Users />} />
      <Route path="/settings" exact element={<Settings />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

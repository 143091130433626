const initialState = [];

function makesReducer(state = initialState, action) {
  switch (action.type) {
    case "makes/init":
      return action.payload;
    case "makes/add":
      //add gateway to state
      return [...state, action.payload];
    case "makes/remove":
      //remove gateway from state
      return state.filter((gateway) => gateway.id !== action.payload);
    case "makes/update":
      return action.payload;

    default:
      return state;
  }
}

export default makesReducer;

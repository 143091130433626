import React from "react";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import fetchConfigFile from "../../functions/fetchConfigFile";
import { Download } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function GenerateConfigButton() {
  const { t } = useTranslation("common");

  const token = localStorage.getItem("access_token");

  const gateway = useSelector((state) => state.gateway);

  return (
    <>
      <Tooltip title={t("common.generate_config_file")}>
        <Button
          variant="outlined"
          color="success"
          onClick={async () => {
            fetchConfigFile(gateway.id, token);
          }}
          startIcon={<Download />}
        >
          {t("common.generate_config_file")}
        </Button>
      </Tooltip>
    </>
  );
}

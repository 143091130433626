import { Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import PageTitle from "../components/Layout/PageTitle";

const NotFound = memo(() => {
  return (
    <Grid container spacing={1}>
      <PageTitle title="404_title" />
      <Grid item xs={12}>
        <Typography variant="h4">404 Not Found, please go back.</Typography>
      </Grid>
    </Grid>
  );
});

export default NotFound;

import React, { useEffect, useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { handleSnackbarClose } from "../../functions/handleSnackbarClose";

export default function SnackbarBase() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const snackbar = useSelector((state) => state.snackbar);

  useEffect(() => {
    setSnackbarOpen(snackbar.open);
    setSnackbarMessage(snackbar.message);
  }, [snackbar]);

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      message={snackbarMessage}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  );
}

import CONFIG from "../config";

export default async function fetchConfigFile(gatewayID, token) {
  //url will redirect to a file, which will be downloaded

  try {
    //open a new window with the url
    const url = `${CONFIG.API_URL}/gateways/${gatewayID}/config?access_token=${token}`;

    window.open(url, "_blank");

    //return the response
    return;
  } catch (error) {
    console.log(error);
  }
}

import deleteData from "../api/Delete";

export default async function deleteGateways(gateways) {
  //check length of gateways array
  if (gateways.length === 0) {
    return;
  }

  gateways.forEach(async (gateway) => {
    await deleteData(`/gateways/${gateway}`);
  });
}

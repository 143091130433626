import { useState } from "react";
import DataTableBase from "../Tables/DataTable";
import { useTranslation } from "react-i18next";
import YAMLEditor from "../Editors/YAMLEditor";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export default function MakesTable() {
  const { t } = useTranslation("common");

  const makes = useSelector((state) => state.makes);

  const [YAMLEditorOpen, setYAMLEditorOpen] = useState(false);

  const [YAMLEditorFile, setYAMLEditorFile] = useState("");
  const [make, setMake] = useState("");

  return (
    <>
      <DataTableBase
        title={t("common.make")}
        columns={[
          {
            name: "name",
            label: t("common.name"),
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "slug",
            label: t("common.slug"),
            options: {
              filter: true,
              sort: true,
            },
          },
          {
            name: "docker_compose_template_url",
            label: t("common.docker_compose_template_url"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta) => {
                return (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setYAMLEditorFile("dockerfile");
                      setMake(tableMeta.rowData[1]);
                      setYAMLEditorOpen(true);
                    }}
                  >
                    {t("common.edit")}
                  </Button>
                );
              },
            },
          },
          {
            name: "gatewayrs_settings_url",
            label: t("common.gatewayrs_settings_url"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta) => {
                return (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setYAMLEditorFile("gatewayrs_settings");
                      setMake(tableMeta.rowData[1]);
                      setYAMLEditorOpen(true);
                    }}
                  >
                    {t("common.edit")}
                  </Button>
                );
              },
            },
          },
        ]}
        data={makes}
      />
      <YAMLEditor
        open={YAMLEditorOpen}
        handleClose={() => setYAMLEditorOpen(false)}
        YAMLEditorFile={YAMLEditorFile}
        make={make}
      />
    </>
  );
}

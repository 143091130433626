import CONFIG from "../config";
import setThemeMode from "./setThemeMode";

const handleThemeChange = (mode = null) => {
  if (mode !== null) {
    setThemeMode(mode);
    localStorage.setItem("theme", mode);
    return;
  }

  if (localStorage.getItem("theme") === null) {
    setThemeMode(CONFIG.defaultTheme === "light" ? "dark" : "light");
    localStorage.setItem(
      "theme",
      CONFIG.defaultTheme === "light" ? "dark" : "light"
    );
  } else if (localStorage.getItem("theme") === "light") {
    setThemeMode("dark");
    localStorage.setItem("theme", "dark");
  } else {
    setThemeMode("light");
    localStorage.setItem("theme", "light");
  }
};

export default handleThemeChange;

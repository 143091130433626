export default function fetchGatewayStatus(gateway) {
  //check if gateway.last_updated is not less than 5 minutes, if so, set status to offline
  let gateway_status = "N/A";

  if (gateway && gateway.last_updated) {
    let last_updated = new Date(gateway.last_updated);
    let now = new Date();

    let diff = now - last_updated;

    if (diff < 360000) {
      gateway_status = "Online";
    } else {
      gateway_status = "Offline";
    }

    return gateway_status;
  } else {
    return gateway_status;
  }
}

import React from "react";
import { Button, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UserAdd from "../Dialogs/UserAdd";

export default function AddUserButton(props) {
  const [addUserDialogOpen, setAddUserDialogOpen] = React.useState(false);

  const { t } = useTranslation("common");

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  return (
    <>
      <Tooltip title={t("common.add_user")}>
        <Button
          variant="outlined"
          color="success"
          onClick={handleAddUserDialogOpen}
          startIcon={<Add />}
        >
          {t("common.add_user")}
        </Button>
      </Tooltip>
      <UserAdd
        open={addUserDialogOpen}
        handleClose={() => setAddUserDialogOpen(false)}
      />
    </>
  );
}

import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

import { Logout, Person, Router, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

function SidebarLayout(props) {
  const navigate = useNavigate();

  const collapsed =
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false;

  const { logout } = useAuth0();

  let theme = useTheme();

  const themeMode = useSelector((state) => state.themeMode);

  const { t } = useTranslation("common");

  return (
    <Sidebar
      collapsed={collapsed}
      className="app"
      backgroundColor={
        themeMode === "dark" ? "#191B1F" : theme.palette.primary.main
      }
      color={themeMode === "dark" ? "#8ba1b7" : "#fff"}
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color: disabled
                  ? "#eee"
                  : themeMode === "dark"
                  ? "#8ba1b7"
                  : "#fff",
                backgroundColor: active ? "#fff" : undefined,
                "&:hover": {
                  backgroundColor: themeMode === "dark" ? "#8ba1b7" : "#fff",
                  color:
                    themeMode === "dark" ? "#fff" : theme.palette.primary.main,
                },
              };
            }
          },
        }}
      >
        <Tooltip title={t("common.gateways")} placement="right">
          <MenuItem onClick={() => navigate("/gateway")} icon={<Router />}>
            {t("common.gateways")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("common.users")} placement="right">
          <MenuItem onClick={() => navigate("/user")} icon={<Person />}>
            {t("common.users")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("common.settings")} placement="right">
          <MenuItem onClick={() => navigate("/settings")} icon={<Settings />}>
            {t("common.settings")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("common.logout")} placement="right">
          <MenuItem
            onClick={() => logout({ returnTo: window.location.origin })}
            icon={<Logout />}
          >
            {t("common.logout")}
          </MenuItem>
        </Tooltip>
      </Menu>
    </Sidebar>
  );
}

export default SidebarLayout;

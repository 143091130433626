import axios from "axios";
import CONFIG from "../config";
import { getHeaders } from "./getHeaders";

//axios api call to delete data from the server
export const deleteData = async (url) => {
  let headers = await getHeaders();
  const response = await axios.delete(CONFIG.API_URL + url, {
    headers: headers,
  });

  return response;
};

export default deleteData;

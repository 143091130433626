import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Router } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomCard from "./Card";

export default function GatewaysStats() {
  const gateways = useSelector((state) => state.gateways);

  const [onlineCount, setOnlineCount] = useState("N/A");
  const [offlineCount, setOfflineCount] = useState("N/A");

  const { t } = useTranslation("common");

  useEffect(() => {
    let online = 0;
    let offline = 0;

    if (!gateways || gateways.length === 0) {
      return;
    }

    gateways.forEach((gateway) => {
      //check last updated is not older than 10 minutes
      let lastUpdated = new Date(gateway.last_updated);
      let now = new Date();

      if (now - lastUpdated < 600000) {
        online++;
      } else {
        offline++;
      }
    });
    setOnlineCount(online);
    setOfflineCount(offline);
  }, [gateways]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.total") + " " + t("common.gateways")}
          action={gateways && gateways ? gateways.length : "N/A"}
          avatar={<Router sx={{ fontSize: "4em" }} />}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.gateways") + " " + t("common.online")}
          action={onlineCount}
          avatar={<Router sx={{ fontSize: "4em" }} />}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <CustomCard
          header={t("common.gateways") + " " + t("common.offline")}
          action={offlineCount}
          avatar={<Router sx={{ fontSize: "4em" }} />}
        />
      </Grid>
    </Grid>
  );
}

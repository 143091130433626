import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import addGateway from "../../functions/addGateway";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import fetchGateways from "../../functions/fetchGateways";
import { store } from "../../store";
import { useSelector } from "react-redux";

function GatewayAdd(props) {
  const [name, setName] = useState("");
  const [make, setMake] = useState("");

  const makes = useSelector((state) => state.makes);

  const { t } = useTranslation("common");

  const handleAddGateway = async () => {
    const newGateway = {
      name: name,
      type: make,
    };

    await addGateway(newGateway);

    updateSnackbarMessage(t("common.gateway_added"));

    fetchGateways().then((gateways) => {
      store.dispatch({ type: "gateways/update", payload: gateways });
    });

    setName("");
    setMake("");

    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{t("common.add_gateway")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("common.name")}
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <TextField
          margin="dense"
          label={t("common.make")}
          type="text"
          fullWidth
          select
          value={make}
          onChange={(event) => setMake(event.target.value)}
        >
          {makes &&
            makes.length &&
            makes.map((make) => (
              <MenuItem key={make.name} value={make.slug}>
                {make.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.handleClose}
          color="secondary"
        >
          {t("common.cancel")}
        </Button>
        <Button variant="outlined" onClick={handleAddGateway} color="primary">
          {t("common.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GatewayAdd;

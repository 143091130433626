import React from "react";
import { MenuItem, TextField } from "@mui/material";
import updateLanguage from "../../functions/updateLanguage";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageDropdown() {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const { t } = useTranslation("common");

  return (
    <TextField
      id="outlined-select-language"
      select
      fullWidth
      label={t("common.language")}
      value={language}
      onChange={(event) => {
        updateLanguage(event.target.value);
        setLanguage(event.target.value);
      }}
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="nl">Nederlands</MenuItem>
      <MenuItem value="de">Deutsch</MenuItem>
    </TextField>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import DataTableBase from "./DataTable";
import { useTranslation } from "react-i18next";
import UserEditDialog from "../Dialogs/UserEdit";
import datatableColumnDefs from "./dataTableColumnDefs";

export default function UsersTable() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);

  const users = useSelector((state) => state.users);

  const { t } = useTranslation("common");

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <DataTableBase
        columns={datatableColumnDefs(t, "users", null)}
        data={users && users.value ? users.value : []}
        options={options}
        title={t("common.users")}
        sortOrder={"name"}
        selectableRows={"multiple"}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={[
          {
            name: "Delete",
            icon: "Delete",
            action: () => {
              //open delete confirmation dialog
            },
          },
        ]}
      />
      <UserEditDialog
        open={editUserDialogOpen}
        onClose={() => {
          setEditUserDialogOpen(false);
        }}
      />
    </>
  );
}

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";

export default function UserEditDialog(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { t } = useTranslation("common");

  const handleGeneratePassword = () => {
    // Generate a secure password here
    let password =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    password = password.split("");
    let securePassword = "";
    for (let i = 0; i < 16; i++) {
      securePassword += password[Math.floor(Math.random() * password.length)];
    }

    setPassword(securePassword);
    //copy to clipboard
    navigator.clipboard.writeText(securePassword);
    updateSnackbarMessage("Password copied to clipboard");
  };

  const handleSave = () => {};

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t("common.edit") + " " + t("common.user")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={t("common.email")}
          fullWidth
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          disabled
        />
        <TextField
          margin="dense"
          label={t("common.password")}
          fullWidth
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          variant="outlined"
          onClick={handleGeneratePassword}
          sx={{ mt: 2 }}
        >
          {t("common.generate_password")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleSave}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

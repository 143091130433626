import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmDelete from "../Dialogs/ConfirmDelete";
import fetchGateways from "../../functions/fetchGateways";
import { store } from "../../store";
import deleteGateways from "../../functions/deleteGateways";
import updateSnackbarMessage from "../../functions/updateSnackbarMessage";
import RebootGateways from "../Dialogs/RebootGateways";
import rebootGateways from "../../functions/rebootGateways";
import datatableColumnDefs from "./dataTableColumnDefs";
import DataTableBase from "./DataTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function GatewaysTable() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [rebootDialogOpen, setRebootDialogOpen] = useState(false);

  const gateways = useSelector((state) => state.gateways);

  const options = {
    filterType: "checkbox",
  };

  return (
    <>
      <DataTableBase
        columns={datatableColumnDefs(t, "gateways", navigate)}
        data={gateways}
        options={options}
        title={t("common.gateways")}
        sortOrder={"last_updated"}
        selectableRows={"multiple"}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={[
          {
            name: "Delete",
            icon: "Delete",
            action: () => {
              setConfirmDeleteOpen(true);
            },
          },
          {
            name: "Reboot",
            icon: "Reboot",
            action: () => {
              setRebootDialogOpen(true);
            },
          },
        ]}
      />

      <ConfirmDelete
        open={confirmDeleteOpen}
        data={selectedItems}
        onClose={() => setConfirmDeleteOpen(false)}
        type="gateway"
        onConfirm={async () => {
          //delete gateways
          deleteGateways(selectedItems);

          //remove from store and update table
          store.dispatch({
            type: "gateways/remove",
            payload: selectedItems,
          });

          updateSnackbarMessage(t("common.gateways_deleted"));
          setConfirmDeleteOpen(false);
          setSelectedItems([]);
          setSelectedRows([]);
        }}
      />

      <RebootGateways
        open={rebootDialogOpen}
        data={selectedItems}
        onClose={() => setRebootDialogOpen(false)}
        type="gateway"
        onConfirm={async () => {
          //reboot gateways
          rebootGateways(selectedItems);
          updateSnackbarMessage(t("common.gateways_rebooted"));
          setRebootDialogOpen(false);
          setSelectedItems([]);
          setSelectedRows([]);
          fetchGateways();
        }}
      />
    </>
  );
}

import React from "react";
import { Card, CardHeader, Typography } from "@mui/material";

const CustomCard = ({ header, action, avatar, contents, actions }) => {
  return (
    <Card>
      <CardHeader
        title={header}
        action={
          <Typography sx={{ py: 3 }} variant="h6">
            {action}
          </Typography>
        }
        avatar={avatar}
        titleTypographyProps={{
          variant: "h6",
        }}
      />
    </Card>
  );
};

export default CustomCard;

import { putData } from "../api/Put";

export default async function rebootGateways(gatewayIDs) {
  if (!gatewayIDs || !gatewayIDs.length) {
    return;
  }

  gatewayIDs.forEach(async (gatewayID) => {
    putData("/gateways/" + gatewayID + "/reboot");
  });
}

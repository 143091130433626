import CONFIG from "../config";

const initialState = localStorage.getItem("theme") || CONFIG.defaultTheme;

//snackbar open reducer
export default function themeModeReducer(state = initialState, action) {
  switch (action.type) {
    case "themeMode/update":
      return action.payload;
    default:
      return state;
  }
}
